// Import our CSS
import styles from '../css/app.pcss';
    var $ = require('jquery');
const axios = require('axios');
// App main
const main = async () => {
    await import('lazysizes/plugins/bgset/ls.bgset');
};

// Execute async functionad
main().then( () => {

    // Helpers
    var parseHTML = function(str) {
        const tmp = document.implementation.createHTMLDocument('tmp');
        tmp.body.innerHTML = str;
        return tmp.body.children;
    };

    var _si = function(selector, context = document, returnNull = false) {
        const tmp = context.querySelector(selector);
        return tmp ? tmp : returnNull ? null : _c('div');
    };
    var _s = function(selector, context = document) {
        return Array.apply(null, context.querySelectorAll(selector));
    };

    var _c = function(elm) {
        return document.createElement(elm);
    };

    var _ael = function(sel, ev, callback) {
        document.addEventListener(ev, e => {
            if (e.target === document) {
                return;
            }
            const that = e.target.closest(sel);
            if (that) {
                callback(that, e);
            }
        });
    };
    // END HELPERS
    const html = _si('html');
    const body = document.body;
    let imageUrl;
    let imageDlUrl = "";
    window.addEventListener('load', function() {
        body.style.opacity = '1';
        body.classList.add('loaded');
    });
    $(document).on('click', '.js--list-filter', function(e) {
        
        if ($(this).hasClass('css--active')) {
            
        } else {
            const dataFilter = $(this).attr('data-filter');
            console.log(dataFilter)
            $('.js--list-filter').removeClass('css--active');
            $('.js--list-filter').addClass('css--inActive');
            $(this).removeClass('css--inActive');
            $(this).addClass('css--active');

            $('.js--list-wrapper').removeClass('css--active').addClass('css--inActive');
            $('[data-id="' + dataFilter +'"]').addClass('css--active').removeClass('css--inActive');
        }
    });

    $(document).on('click', '.js--toggle-submenu', function(e) {
        const submenu = $(this).children('.js--submenu');
        $(submenu).toggleClass('lg:hidden');
    });
    $(document).on('click', '#js--toggle-menu', function(e) {
        $('body').toggleClass('nav-open');
        $('nav').toggleClass('hidden');
    });
    $(document).on('click', 'nav a', function(e) {
        $('.js--submenu').addClass('hidden');
    });
    $(document).on('click', '.js--image-dl', function(e) {
        const imageUrl = $(this).attr('data-image-url');
        const imageDesc = $(this).attr('data-image-desc');
        const imageCr = $(this).attr('data-image-cr');
        let imageDlUrl = 
        $(".js--imageOverlay").removeClass("hidden");
        $('.js--imageBig').attr('src', imageUrl);
        $('.js--imagedesc').html(imageDesc);
        $('.js--copyrights').html('Copyright: ' + imageCr);
    });
    
    $(document).on('click', '.js--close-imageOverlay', function(e) {
        $(".js--imageOverlay").addClass("hidden");

    });
    _ael('form button', 'click', (elm, e) => {
        e.preventDefault();
        var form = elm.parentNode;
        if (form.classList.contains('sending')) {
            return;
        }
        var errors = false;
        _s('input, textarea', form).forEach(function(elm) {
            if (elm.value === '') {
                errors = true;
                elm.classList.add('error');
            }
        });
        if (errors) {
            return;
        }
        html.classList.add('toggle--form-sending');
        axios
            .post(form.action.value, new FormData(form))
            .then(response => {
                if (response.data.success) {
                    html.classList.add('toggle--form-sent');
                } else {
                    _si('.email', form).classList.add('error', 'wrong');
                }
            })
            .catch(error => {
                _si('input[type="email"]', form).classList.add('error');
            });
    });
    
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
